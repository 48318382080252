@font-face {
  font-family: "Mona Sans";
  src: url("Mona-Sans.woff2") format("woff2 supports variations"),
    url("Mona-Sans.woff2") format("woff2-variations");
  font-weight: 200 900;
  font-stretch: 75% 125%;
}

/*@import url("typography-small.css") only screen and (max-width: 640px);
@import url("typography-medium.css") only screen and (min-width: 641px) and
  (max-width: 960px);
@import url("typography-large.css") only screen and (min-width: 961px);*/

/*
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap')

.plus-jakarta sans-<uniquifier> {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
}*/

/*
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap')

.lora-<uniquifier> {
  font-family: "Lora", serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
}
*/

html,
body {
  max-width: 100vw;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background: #f9f9f9;
}

mark {
  display: inline-block;
  background-color: transparent;
  position: relative;
  z-index: 1;
  color: currentcolor;
}

mark:before {
  background-image: url("data:image/svg+xml,%3Csvg width='134' height='26' viewBox='0 0 134 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg style='mix-blend-mode:multiply'%3E%3Cpath d='M8.40808 2.24112L133.103 1.25566L133.101 1.52921L98.5007 1.85092L122.532 1.73623L133.099 1.80276L132.374 3.166L60.5223 3.11637L132.191 3.27949L131.266 4.51936L118.709 4.71385L131.086 4.79178L130.523 5.95584L124.041 11.8565L121.786 17.3893L6.34826 17.4926L126.582 16.0758L125.838 17.4941L122.393 22.389L121.485 24.398L105.882 24.2924L89.4047 24.1886L60.3883 24.4037L46.2518 24.0608L26.8935 24.3504L15.1 24.0227L1.39176 23.9334L2.82849 20.802L4.21478 16.4996L40.0277 16.8302L1.02818 19.2136L7.06347 7.18505L14.9956 6.88327L7.07599 6.89391L8.40808 2.24112Z' fill='%23E91D63' /%3E%3C/g%3E%3C/svg%3E%0A");
  overflow: visible;
  background-size: 100%;
  background-position: 50% center;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  inset: 0px -0.125em;
  z-index: -1;
  transform: rotate(-2deg);
}
